import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "draft": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Notes`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Building Blocks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Iterations on a Theme`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Good, The Bad, and The Cascade`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`birth of styled-system: `}<a parentName="p" {...{
            "href": "https://github.com/jxnblk/axs/commit/1c1c25ad5c767f5859e50f6134ba188dc6cbf95b"
          }}>{`https://github.com/jxnblk/axs/commit/1c1c25ad5c767f5859e50f6134ba188dc6cbf95b`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/jxnblk/axs/commit/5996eecedf7b4b2821cd1b4f5f8fe09efa684ac9"
            }}>{`https://github.com/jxnblk/axs/commit/5996eecedf7b4b2821cd1b4f5f8fe09efa684ac9`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`css prop: `}<a parentName="p" {...{
            "href": "https://github.com/jxnblk/axs/commit/b54d8527e6e19ec1177cb8894af9870a84a16962"
          }}>{`https://github.com/jxnblk/axs/commit/b54d8527e6e19ec1177cb8894af9870a84a16962`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      